

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.itemLabel {
  min-width: 5rem;
}

.upload-btns {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.el-textarea__inner {
  min-height: 7.5rem !important;
  resize: none;
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.dialog-footer {
  margin-top: .75rem;
  display: flex;
  justify-content: flex-end;
}
